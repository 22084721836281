.certificate {
  position: absolute;
  width: 100%;
  top: 0;
}

.just-for-capture {
  animation: smoothAppear 1s;
  margin: 0 auto;
  width: 70%;
}

.close-button {
  margin-top: auto;
  display: inline-block;
  width: 7.6vw;
  height: 7.6vw;
  background-color: white;
  border-radius: 3.8vw;
  border: 1px solid white;
  position: relative;
  top: -15px;
  left: calc(85% - 7.6vw);
}
.x-stroke {
  background-color: black;
  position: absolute;
  width: 5vw;
  height: 2.1px;
  top: 45%;
  left: calc(50% - 2.5vw);
  transform: rotate(45deg);
}
.x-stroke:first-child {
  transform: rotate(-45deg);
}

.certification-container {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.85);
}

.certification-content-container {
  width: 100%;
  border-radius: 1rem;
  border: solid 1px #fff;
  overflow: hidden;
  background-image: url("../../../public/imgs/card_bg.jpg");
  background-size: cover;
  padding: 1.5rem;
}

.certification-content h1 {
  text-align: center;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 700;
}
.certification-content span {
  display: block;
  width: max-content;
  font-weight: 700;
  font-size: 0.7rem;
  margin: 0 auto;
}

.certification-content img {
  display: block;
  margin: 1rem auto 0;
  width: 50%;
  transform: translateX(-10px);
}

.certification-content > span:nth-child(4) {
  margin-top: 0.8rem;
  padding: 0 1rem;
  border: 1px solid black;
  border-radius: 1rem;
}

.certification-content > h1:nth-child(5) {
  margin-top: 0.5rem;
  font-size: 1.3rem;
}

.certification-info {
  display: flex;
  justify-content: space-between;
  width: max-content;
  margin: 1.5rem auto 3rem;
}
.certification-info p {
  margin: 0 0.5rem;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1.2;
}
.certification-info p:nth-child(2) {
  text-align: center;
}

.certification-logo-container {
  width: 45%;
  margin: auto;
}

.certification-logo-container svg {
  width: 100%;
}

.certification-logo-word {
  font-size: 10px;
  font-weight: 700;
  margin-top: -3px;
  text-align: center;
}

.certification-save {
  text-align: center;
  margin: 1rem 0 auto;
  color: #0094ff;
  text-decoration: none;
}

@media screen and (min-width: 500px) {
  .just-for-capture {
    width: 400px;
  }

  .close-button {
    width: 30px;
    height: 30px;
    background-color: white;
    border-radius: 15px;
    position: relative;
    left: calc(50% + 200px - 30px);
  }
  .x-stroke {
    background-color: black;
    width: 20px;
    height: 2px;
    top: 45%;
    left: calc(50% - 10px);
  }
  .certification-content-container {
    border-radius: 2rem;
  }
}
