

@font-face {
  font-family: "Pretendard";
  font-weight: 600;
  font-display: swap;
  src: local("Pretendard SemiBold"),
    url("./fonts/woff2/Pretendard-SemiBold.woff2") format("woff2"),
    url("./fonts/woff/Pretendard-SemiBold.woff") format("woff");
}

@font-face {
  font-family: "Cardo";
  font-weight: 400;
  font-display: swap;
  src: local("Cardo Regular"),
    url("./fonts/ttf/Cardo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 500;
  font-display: swap;
  src: local("Pretendard Medium"),
    url("./fonts/woff2/Pretendard-Medium.woff2") format("woff2"),
    url("./fonts/woff/Pretendard-Medium.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 400;
  font-display: swap;
  src: local("Pretendard Regular"),
    url("./fonts/woff2/Pretendard-Regular.woff2") format("woff2"),
    url("./fonts/woff/Pretendard-Regular.woff") format("woff");
}

@font-face {
  font-family: "Pretendard";
  font-weight: 300;
  font-display: swap;
  src: local("Pretendard Light"),
    url("./fonts/woff2/Pretendard-Light.woff2") format("woff2"),
    url("./fonts/woff/Pretendard-Light.woff") format("woff");
}

* {
  box-sizing: border-box;
  font-family: "Pretendard";
  font-size: 16px;
}
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
.body-container {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}
.height-80vh {
  height: 80vh;
}

@media screen and (min-width: 800px) {
  * {
    font-size: 20px;
  }
  .body-container {
    height: 95vh;
  }
}

.none {
  display: none;
}

img {
  vertical-align: unset;
}
