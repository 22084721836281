.slide-bg{
    background-color: #f1f1f1;
    height: 4vh;
    min-height: 3vh;
    border-bottom: 1px solid black;
}

.slide-bar{
    width: max-content;
    line-height: 4vh;
    animation-duration: 35s;
    animation-name: slidein;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.slide-bar-content{
    font-size: 0.8rem;
    color: black;
    margin: 0 4rem;
}

.slide-thx{
    height: 4vh;
    min-height: 3vh;
    border-bottom: 1px solid black;
    position: relative;
}

.slide-thx span{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 0.8rem;
}

@keyframes slidein {
    from {
        margin-left: 100%;
    }
  
    to {
        margin-left: -130rem;
   }
  }


  @media screen and (min-width: 800px) {
    
    .slide-bar-content{
        margin: 0 10rem;
    }

    .slide-bar{
        animation-duration: 50s;
    }
    @keyframes slidein {
        from {
            margin-left: 100%;
        }
      
        to {
            margin-left: -185rem;
       }
      }
  }