.intro-content {
  animation: appear 1s ease-in-out;
}

.logo-gif {
  position: relative;
}

.logo-gif img {
  display: block;
  width: min(60%, 400px);
  margin: 0 auto;
}
.logo-gif span {
  font-size: 0.8rem;
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.intro {
  display: flex;
  flex-direction: column;
  height: 94vh;
}

.img-container {
  position: relative;
  margin-top: 1rem;
}

.img-container img {
  height: 12rem;
  display: block;
  width: min(80%, 400px);
  margin: 0 auto;
  border-radius: 5%;
  object-fit: cover;
}
.img-word {
  font-weight: 600;
  width: max-content;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.intro-content h5 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  margin: 3em auto;
}
.intro-content h4 {
  text-align: center;
  font-family: "Cardo";
  margin-bottom: 3em;
}

.intro-content p {
  text-align: center;
  font-size: 0.8rem;
  line-height: 1.5;
  margin: 0;
}

.intro-container {
  max-width: 700px;
  height: max(6rem, max-content);
  margin: 2em auto;
  padding: 10px;
  border-radius: 10px;
  width: 90%;

  cursor: pointer;
}
.intro-container-last {
  margin-bottom: 5rem;
}

.intro-container-clicked {
  max-width: 700px;
  height: max(6rem, max-content);
  margin: 3em auto;
  padding: 10px;
  width: 90%;
  cursor: pointer;
}

.intro-title {
  position: relative;
  height: max-content;
  margin-top: 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.intro-title span {
  position: absolute;
  right: 0;
  bottom: 0.4rem;
  font-size: 1.3rem;
  font-weight: 300;
}

.intro-title-clicked span {
  position: absolute;
  right: 0;
  bottom: 0.1rem;

  font-size: 1.3rem;
  font-weight: 300;
}
.intro-title h5 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.intro-title-clicked h5 {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: black;
}

.intro-title-clicked {
  position: relative;

  height: max-content;
  margin-top: 2.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.intro-none {
  display: none;
}

.third {
  width: 80%;
  margin: auto;
  max-width: 700px;
}

.third h6 {
  margin-top: 3.2rem;
  margin-bottom: 1rem;
  color: rgba(0, 0, 0, 0.8);
}

.third div {
  margin-top: 1rem;
  line-height: 1.5;
  font-size: 0.8rem;
}

.intro-footer {
  margin-top: auto;
  border-top: 1px solid black;
  padding: 20px;
}

.intro-footer-word {
  position: relative;
  overflow: hidden;
}

.intro-footer-word div {
  display: inline-block;
  color: black;
  margin: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.3;
}

.intro-footer-word img {
  position: absolute;
  height: 1.2rem;
  top: 50%;
  right: 0px;
  transform: translate(0, -50%);
}

.appear {
  animation: appear 0.5s ease-in-out;
}
.disappear {
  animation: disappear 1s ease-in-out;
}

@keyframes appear {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
@keyframes disappear {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
