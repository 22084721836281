.main-header {
  display: flex;
  align-items: center;
  position: relative;
  height: 6vh;
  border-bottom: 1px solid black;
}

.logo-wrapper {
  width: 60%;
  height: 100%;

  border-right: 1px solid black;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.logo-wrapper img {
  height: 0.9rem;
  object-fit: contain;
}

.nav-items {
  width: 20%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.main-header div:nth-child(2) {
  border-right: 1px solid black;
}

.main-header a {
  text-decoration: none;
  color: black;
  font-weight: 500;
}

.main-header :hover {
  color: gray;
}
