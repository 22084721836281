.search-bar {
  margin-top: 32vh;
  display: flex;
  justify-content: center;
}

.search-bar-container {
  max-width: 600px;
  width: 70%;
  border: 1px solid black;
  padding: 0;
  box-sizing: content-box;
}
input[type="text"] {
  font-size: 16px;
}

.search-bar-input {
  text-align: center;
  letter-spacing: 0.2em;
  width: calc(100% - 3rem);
  border: none;
  padding: 2px 0;
}

.search-bar-input:focus {
  outline: 0;
}

.search-bar-input::placeholder {
  color: #ccc;
  letter-spacing: normal;
  font-size: 14px;
}

.search-bar-button {
  color: black;
  padding: 2px 0;
  width: 3rem;
  float: right;
  border: 0;
  background-color: white;
  border-left: 1px solid black;
}

.search-bar-button:hover {
  background-color: rgba(244, 245, 245, 0.6);
}

.search-bar-button:active {
  box-shadow: inset -2px -2px 3px rgba(255, 255, 255, 0.6),
    inset 2px 2px 3px rgba(66, 64, 64, 0.6);
}

.search-bar-alert {
  width: calc(80% - 3rem);
  margin-left: calc(10vw);
  margin-top: 6px;
  font-size: 0.7rem;
  color: red;
  text-align: center;
}

.is-invalid {
  border-color: red;
  color: gray;
}
.is-invalid::placeholder {
  color: #ddd;
}

@media screen and (min-width: 800px) {
  .search-bar {
    margin-top: auto;
  }

  .search-bar-alert {
    width: calc(600px - 3rem);
    margin-left: calc(50vw - 300px);
  }
}
