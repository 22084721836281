.present-container p:nth-child(2) {
  border: 1px solid black;
  border-radius: 1rem;
  width: 5rem;
  margin: 0.6rem auto 0.5rem;
}
.present-container > p {
  line-height: 1.3;
  font-size: 0.8rem;
  text-align: center;
  font-weight: 500;
  margin-top: 0.1rem;
  margin-bottom: 0.4rem;
}
.present-container > h6 {
  text-align: center;
  font-weight: 600;
  margin: 0;
}
.present-container > img {
  border-radius: 50%;
  display: block;
  height: 20vh;
  margin: 1rem auto;
  object-fit: contain;
}

.present-container {
  border-radius: 3vw;
  width: min(60vw, 300px);

  padding-top: 2.5rem;
  margin: 0 auto;
  text-align: center;
}
