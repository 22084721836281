.status-container {
  margin: 2.5rem auto;
  width: max-content;
}

.status-circle {
  display: inline-block;
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  background-color: #eee;
}

.status-sentence {
  width: max-content;
  display: inline-block;
  margin-left: 1.1rem;
  vertical-align: middle;
  font-weight: 500;
}

.status-detail {
  font-size: 0.8rem;
  position: absolute;
}

.status-line {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 4rem;
}
.status-line-2 {
  margin-bottom: 5rem;
}
.stroke {
  position: absolute;
  width: 2px;
  height: 4rem; /* 원지름 + p높이 + h3 마진 탑 */
  background-color: #eee;
  top: 100%;
  left: 0.9rem;
}
.stroke-5rem {
  height: 5rem;
}

.yellow-bg {
  background-color: rgba(255, 199, 1, 0.94);
}

.alarm-button {
  background-color: rgba(255, 199, 1, 0.94);
  border: none;
  color: white;
  display: block;
  margin: 0 auto;
  font-weight: 700;
  text-align: center;
  width: 85%;
  padding: 0.5rem 0;
  cursor: pointer;
}

.bang-head-stroke {
  margin: 0.3rem auto 0;
  width: 2px;
  height: 0.9rem;
  background-color: #fff;
}

.bang-foot-stroke {
  margin: 0.15rem auto 0;
  width: 2px;
  height: 3px;
  background-color: #fff;
}

.not-active {
  color: rgba(0, 0, 0, 0.1);
}

.dot {
  position: absolute;
  top: calc(50% - 2px);
  left: calc(0.9rem - 2px);
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.dot::before {
  content: "";
  position: absolute;
  display: inline-block;
  left: -8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.dot::after {
  content: "";
  position: absolute;
  display: inline-block;
  left: 8px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #fff;
}

.check-icon-container {
  z-index: 999;
  margin: 0.23rem auto;
  display: block;
  width: 60%;
  height: 60%;
}

.check-icon-container > svg {
  display: block;
}

.present-button {
  background-color: rgba(0, 148, 255, 0.85);
  border: none;
  color: white;
  display: block;
  margin: 6rem auto 0;
  font-weight: 700;
  text-align: center;
  width: 85%;
  padding: 0.5rem 0;
}

.present-footer-container {
  margin: 2rem 0;
}

.footer_copyright {
  font-size: 0.6em;
  color: #6e6e73;
  text-align: center;
}

.blue-bg {
  background-color: #0094ff;
}

.margin-bottom-2rem {
  margin-bottom: 2rem;
}
